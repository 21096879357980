<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                            <v-text-field
                                v-model="item.label"
                                :label="trans('fields.common.label')"
                                :error-messages="errors.label"
                                @input="clearError('label')"
                            ></v-text-field>
                            <v-select
                                v-if="options"
                                :items="options.cpaNetworkScopes"
                                :label="trans('fields.common.scope')"
                                v-model="item.scope"
                                :error-messages="errors.scope"
                                @input="clearError('scope')"
                            ></v-select>
                            <user-autocomplete
                                v-if="item.scope == 2 && !user_id"
                                v-model="item.user_id"
                                :label="trans('fields.common.user')"
                                :error-messages="errors.user_id"
                                @input="clearError('user_id')"
                            ></user-autocomplete>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="itemId" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    import UserAutocomplete from "../user/UserAutocomplete";
    export default {
        name: "CpaNetworkForm",
        mixins: [formValidate],
        components: {
            UserAutocomplete
        },
        props: {
            itemId: {
                type: [Number, undefined],
            },
            user_id: {
                type: [Number, undefined],
            }
        },
        data(){
            return {
                item: {
                    id: null,
                    label: '',
                    scope: null,
                    user_id: this.user_id,
                },
                options: {},
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                this.loadOptions();
                if (this.itemId){
                    axios.get(this.route('admin.cpaNetworks.show', this.itemId)).then( response => {
                        this.item = response.data;
                    }).catch( err => {
                        console.log(err);
                    })
                }
            },
            loadOptions(){
                axios.post(this.route('admin.options'), {
                    cpaNetworkScopes: true,
                }).then( response => {
                    this.options = response.data;
                })
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route('admin.cpaNetworks.store'), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.cpaNetworks.update', this.itemId), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.itemId
                    ? this.trans('pages.cpaNetwork.newDialogTitle')
                    : this.trans('pages.cpaNetwork.editDialogTitle');
            },
        }
    }
</script>
